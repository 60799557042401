import { render, staticRenderFns } from "./UserServiceUpdate.vue?vue&type=template&id=53b1cff6&scoped=true&"
import script from "./UserServiceUpdate.vue?vue&type=script&lang=js&"
export * from "./UserServiceUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b1cff6",
  null
  
)

export default component.exports