<template>
  <div>
    <user-service-create :user-service-update="userService" :title="$t('user_service.page.update')" :button-text="$t('user_service.buttons.update')"></user-service-create>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
import UserServiceCreate from "@/views/user-service/UserServiceCreate";
import {UserUsageService} from "@/services";

export default {
  name: "UserServiceUpdate",
  components: {UserServiceCreate},
  data() {
    return {
      userService: {},
    }
  },
  computed: {
    userServiceId() {
      return this.$route.params.id
    }
  },
  methods: {
    async getUserService() {
      if (this.userServiceId) {
        await UserUsageService.detail(this.userServiceId).then((res) => {
          if(res && res.data) {
            this.userService = res.data
          }
        }).catch((err) => {
          this.$toast.error(err.data.message)
        })
      }
    },
  },
  created() {
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'user-service')
    this.getUserService()
  }
}
</script>

<style lang="scss" scoped>

</style>